<template>
  <div>

    <PageTitle page-title="Warranty" />

    <!--Our-Services-->
    <section id="services" class="section-padding">
      <div class="container">
        <div class="section-header text-center">
          <h2>Warranty <span>For Peace of Mind</span></h2>
          <p>All vehicles include a 12 months nationwide parts and garage warranty, for peace of mind.</p>
          <p>UK breakdown cover provides 24/7 roadside assistance and they will try and fix your car on the spot. You can even track the mechanic right to your side via the app.</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="services_image">
              <img src="../assets/images/midlandautosales-leicester-aa-warranty2.jpg" alt="image">
              <div class="service_heading white-text">
                <h3>Warranty</h3>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h3>Parts and garage <span>cover</span></h3>
            <p>Claim up to 5 times a year, each up to £535 with a £35 excess. This excludes pre-existing faults and any that happen within the first 14 days of cover.</p>
            <br>

          </div>
        </div>
        <!-- <div class="space-80"></div>
        <div class="row">
          <div class="col-md-6 col-md-push-6">
            <div class="services_image"> <img src="assets/images/600x380.jpg" alt="image">
              <div class="service_heading white-text">
                <h3>Sell Used Cars</h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-md-pull-6">
            <h3>Looking <span>Used Car?</span></h3>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
            <br>
            <h4>At vero eos et accusamus et iusto odio dignissimos</h4>
            <ul class="list_style_none">
              <li><i class="fa fa-check" aria-hidden="true"></i> Pellentesque lacus urna, feugiat non consectetur</li>
              <li><i class="fa fa-check" aria-hidden="true"></i> Aliquam sem neque, efficitur atero lectus vitae</li>
              <li><i class="fa fa-check" aria-hidden="true"></i> Pellentesque erat libero, eleifend sit amet felis</li>
            </ul>
          </div>
        </div> -->
      </div>
    </section>
    <!-- /Our-Services-->

    <!-- Why-Choose-Us-->
    <!-- <section class="why_choose_us section-padding gray-bg">
      <div class="container">
        <div class="section-header text-center">
          <h2>Why <span>Choose Us</span></h2>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. </p>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="listing_box">
              <h5><i class="fa fa-user-circle" aria-hidden="true"></i> Trusted By Thousands</h5>
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
            </div>
            <div class="listing_box">
              <h5><i class="fa fa-globe" aria-hidden="true"></i> Wide Range Of Vehicles</h5>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
            <div class="listing_box">
              <h5><i class="fa fa-car" aria-hidden="true"></i> Faster Buy & Sell</h5>
              <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="video_box">
              <iframe class="mfp-iframe" src="https://www.youtube.com/embed/rqSoXtKMU3Q" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- /Why-Choose-Us-->

    <!--Testimonial -->
    <!-- <section class="section-padding testimonial-section parallex-bg"> -->
      <!-- <div class="container div_zindex">
        <div class="section-header white-text text-center">
          <h2>What Our <span>Client Say</span></h2>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. </p>
        </div>
        <div class="row">
          <div id="testimonial-slider">
            <div class="testimonial-m">
              <div class="testimonial-img"> <img src="assets/images/300x300.jpg" alt="" /> </div>
              <div class="testimonial-content">
                <div class="testimonial-heading">
                  <h5>Donald Brooks</h5>
                  <span class="client-designation">CEO of xzy company</span> </div>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>
            </div>
            <div class="testimonial-m">
              <div class="testimonial-img"> <img src="assets/images/300x300.jpg" alt="" /> </div>
              <div class="testimonial-content">
                <div class="testimonial-heading">
                  <h5>Enzo Giovanotelli</h5>
                  <span class="client-designation">CEO of xzy company</span> </div>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>
            </div>
            <div class="testimonial-m">
              <div class="testimonial-img"> <img src="assets/images/300x300.jpg" alt="" /> </div>
              <div class="testimonial-content">
                <div class="testimonial-heading">
                  <h5>Donald Brooks</h5>
                  <span class="client-designation">CEO of xzy company</span> </div>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>
            </div>
            <div class="testimonial-m">
              <div class="testimonial-img"> <img src="assets/images/300x300.jpg" alt="" /> </div>
              <div class="testimonial-content">
                <div class="testimonial-heading">
                  <h5>Enzo Giovanotelli</h5>
                  <span class="client-designation">CEO of xzy company</span> </div>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>
            </div>
            <div class="testimonial-m">
              <div class="testimonial-img"> <img src="assets/images/300x300.jpg" alt="" /> </div>
              <div class="testimonial-content">
                <div class="testimonial-heading">
                  <h5>Donald Brooks</h5>
                  <span class="client-designation">CEO of xzy company</span> </div>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>
            </div>
            <div class="testimonial-m">
              <div class="testimonial-img"> <img src="assets/images/300x300.jpg" alt="" /> </div>
              <div class="testimonial-content">
                <div class="testimonial-heading">
                  <h5>Enzo Giovanotelli</h5>
                  <span class="client-designation">CEO of xzy company</span> </div>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Dark Overlay-->
      <!-- <div class="dark-overlay"></div> -->
    <!-- </section> -->
    <!-- /Testimonial-->
  </div>
</template>

<script>
export default {
  name: 'Warranty',
  components: {
    PageTitle: () => import('../components/PageTitle'),
  },
}
</script>

<style scoped>
.page-header {
  background-image: url('../../public/assets/images/midland-auto-sales-leicester-warranty-header.jpg');
}
</style>